'use strict';

$(document).ready(function () {
    var $productTile = $('.right-content');

    $productTile.each(function() {
        if($(this).hasClass('custom-image')){
            var url = $(this).data('url')
            $(this).find('.tile-image').attr("src", url);
        }

        if($(this).hasClass('custom-badge')){
            var customBadge = $(this).data('text-badge');
            var customBadgeBackgroundColor = $(this).data('bg-badge');
            var customBadgeFontColor = $(this).data('color-badge');
            var productBadge = $(this).find('.product-badge');

            if (productBadge.length === 0) {
                productBadge = "<div class='product-badge' style='color:" + customBadgeFontColor + "; background-color:" + customBadgeBackgroundColor + "'>" + customBadge + "</div>"
                $(this).find('.link-pdp').prepend(productBadge);
            } else {
                productBadge.css({'backgroundColor' : customBadgeBackgroundColor, 'color': customBadgeFontColor});
                productBadge.text(customBadge);
            }
        }

        var textBackgroundColor = $(this).data('text-background');
        var viewSize = $(window).width();
        
        if (!!textBackgroundColor) {
            var reviewTileText = $(this).find('.tile-body');
            reviewTileText.css({ 'backgroundColor': viewSize <= 768 ? textBackgroundColor : "transparent" });
        }

        var link = $(this).find('.link-pdp').attr('href')
        $(this).find('.image-container').append("<a class='shop-now hidden-sm-down' href='" + link + "' tabindex='0'> Shop now <div class='icon-container'></div></a>")
    });
    if ($('.review-prices').length > 0) {
        $('.review-prices').html($('.product-details .pdp-price').clone());
    }
});
